/*

Theme: ETAT - Slick contemporary multipurpose theme
Author: Webinning
Author URI: https://webinning.co.uk

---

Copyright 2018 Webinning

*/


'use strict';

// Preloader

var Preloader = new Promise(function(resolve) {
    // Variables
    var $preloader = $('.preloader'),
        $spinner = $('.spinner');

    
    // Methods
    function init() {
        $spinner.delay(750).fadeOut();

        setTimeout(function(){
            $preloader.delay(750).fadeOut('slow');
            
            resolve();
        }, 350);
    }
    

    // Events
    if($preloader.length) {
        $(window).on({
            'load': function() {
                init();
            }
        })
    }
});



// Navbar colors

var Navbar = (function() {
    // Variables
    var $navbar = $('.etat-navbar'),
        $menuToggle = $('.index .menu-toggle .hamburger span').not('.menu-toggle .cross span'),
        $transparent = $navbar.data('transparent'),
        $textColor = $navbar.data('text-color'),
        $origBgColor = $navbar.css('background-color'),
        $navbarText = $navbar.find('.navbar-text'),
        $navbarTextLink = $navbar.find('.navbar-text a:not(".btn")'),
        $firstSection = $('main').find('section:first-child');
    
    // Methods
    function init() {
        var scrollTop = $(window).scrollTop(),
            $width = $(window).width(),
            height = $firstSection.find('.bg-container').length ? $firstSection.outerHeight() : 800,
            calc = ((scrollTop / height) * 1.5).toString();

        if($width >= 992) {
            if($origBgColor.indexOf('a') == -1){
                var newColor = $origBgColor.replace(')', ', ' + calc + ')').replace('rgb', 'rgba');
                $navbar.attr('style', 'background-color: ' + newColor + '!important');
    
                if(calc > '0.4') {
                    $navbarText.css('color', $textColor);
                    $menuToggle.css('background', $textColor);
                    $navbarTextLink.css('color', $textColor);
                } else {
                    $navbarText.css('color', '');
                    $menuToggle.css('background', '');
                    $navbarTextLink.css('color', '');
                }
    
                if (calc > '0.97') {
                    newColor = $origBgColor.replace(')', ', 0.97)').replace('rgb', 'rgba');
                    $navbar.attr('style', 'background-color: ' + newColor + '!important');
                }
            }   
        } else {
            $navbar.attr('style', 'background-color: ' + $origBgColor + '!important');
            $navbarText.css('color', $textColor);
            $menuToggle.css('background', $textColor);
            $navbarTextLink.css('color', $textColor);
        }
    }
    

    // Events
    if($navbar.length && typeof $transparent != 'undefined') {
        init();

        $(window).on({
            'scroll resize': function() {
                init();
            }
        });
    }
}());



// ETAT Menu

var Menu = (function() {
    // Variables
    var $toggler = $('.menu-toggle, .etat-nav-link'),
        $overlay = $('.etat-navbar-overlay'),
        $nav = $('.etat-navbar-nav'),
        $search = $('.search i'),
        $navText = $('.navbar-text');

    
    // Methods
    function openOverlay() {
        $toggler.addClass('open');
        $overlay.addClass('open');
        $nav.addClass('open');
        $navText.css('z-index', -1);
    }

    function closeOverlay() {
        $nav.removeClass('open');

        setTimeout(function(){
            $toggler.removeClass('open');
            $overlay.removeClass('open');
            $navText
            .delay(800)
            .queue(function (next) { 
                $(this).css('z-index', 0); 
                next(); 
            });
        }, 500);
    }

    
    

    // Events
    if($overlay.length) {
        $toggler.on({
            'click': function() {
                if(($(this)).hasClass('open')){
                    closeOverlay();
                } else {
                    openOverlay();
                }
            }
        });   
    }
})();



// Dropdown toggle (animated)

var DropdownToggle = (function() {
    //Variables
    var $toggle = $('.dropdown-toggle'),
        $dropdown = $('.dropdown-menu');

    
    // Methods
    function init($this) {
        $dropdown.not($this.next('.dropdown-menu')).slideUp(500);
        $this.next('.dropdown-menu').slideToggle(500);
    }
    

    // Events
    if($toggle.length) {
        $toggle.on({
            'click': function() {
                init($(this));
            }
        });
    }
}());



// Sticky

var Sticky = (function() {
    //Variables
    var $sticky = $('[data-toggle="sticky"]');

    
    // Methods
    function init($this){
        var $width = $(window).width(),
            $mobile = typeof $this.data('sticky-disable-mobile') != 'undefined' ? $this.data('sticky-disable-mobile') : true;

        if($mobile) {
            if ($width >= 992) {
                stick($this);
            } else {
                unstick($this);
            }
        } else {
            stick($this);
        }
    }

    function stick($this) {
        var $offsetTop = $this.data('sticky-offset-top') || 0,
            $parentSelector = $this.data('sticky-parent') || 'section',
            $bottoming = typeof $this.data('sticky-bottom') != 'undefined' ? $this.data('sticky-bottom') : true;

        $this.stick_in_parent({
            'parent': $parentSelector,
            'offset_top': $offsetTop,
            'bottoming': $bottoming
        });
    }

    function unstick($this) {
        $this.trigger("sticky_kit:detach");
    }
    

    // Events
    if($sticky.length) {
        $sticky.each(function() {
            init($(this));      
        });

        $(window).on({
            'resize': function() {
                $sticky.each(function() {
                    init($(this)); 
                });
            }
        })
    }
}());

// Smooth scroll

var SmooothScroll = (function() {
    // Variables
    var $link = $('[data-smooth-scroll]'),
        $offset = $link.data('smooth-scroll-offset') || 0,
        $body = $('html, body');

    
    // Methodss
    function init($this) {
        var $hash = $this.data('smooth-scroll-hash');
        
        // If you want to stop prevent url changes, like #link just add
        if(typeof $hash != 'undefined' && $hash === false) {
            event.preventDefault();
        }
        scrollTo($this);
    }

    function scrollTo($this) {
        var $elem = $this.attr('href') ? $this.attr('href') : $this;

        $body.stop(true, true).animate({
            scrollTop: $($elem).offset().top - $offset
        }, 1000, function(){
            
        });
    }
    

    // Events
    if ($link.length && $link.hash !== '') {
        $link.on({
            'click': function(event) {
                init($(this));
            }
        })
    }
}());

// Background text

var BackgroundText = (function() {
    // Variables
    var $bg = $('[data-background-text], .bg-text');

    // Methods
    function init($this) {
        var $color = $this.data('color'),
            $opacity = $this.data('opacity'),
            $fontSize = $this.data('font-size'),
            $fontWeight = $this.data('font-weight'),
            $offsetX = $this.data('offset-x'),
            $offsetY = $this.data('offset-y'),
            $padding = $this.data('padding'),
            $margin = $this.data('margin'),
            $letterSpacing = $this.data('letter-spacing');

        $this.css({
            'color': $color,
            'opacity': $opacity,
            'font-size': $fontSize,
            'font-weight': $fontWeight,
            'left': $offsetX,
            'top': $offsetY,
            'padding': $padding,
            'margin': $margin,
            'letter-spacing': $letterSpacing
        })
    }  

    // Events
    if($bg.length) {
        $bg.each(function() {
			init($(this));
        });
    }
}());

// Typed text

var Typed = (function() {
    // Variables
    var $typed = $('[data-typed-text], .typed');

    
    // Methods
    function init($this, index) {
        var id = 'typed_' + index,
            $strings = $this.data('typed-text').split('###'),
            $loop = typeof $this.data('typed-loop') != 'undefined' ? $this.data('typed-loop') : true,
            $typeSpeed = $this.data('typed-speed') || 100,
            $backSpeed = $this.data('typed-back-speed') || 50,
            $backDelay = $this.data('typed-back-delay') || 1000,
            $startDelay = $this.data('typed-start-delay') || 0,
            $cursorChar = $this.data('typed-cursor') || '';

        $this.attr('data-typed-id', id);

		var options = {
			strings: $strings,
            typeSpeed: $typeSpeed,
            backSpeed: $backSpeed,
            startDelay: $startDelay,
            cursorChar: $cursorChar,
            loop: $loop,
            backDelay: $backDelay
        };

        // Init TypedJS
        var typed = new Typed('[data-typed-id='+ id +']', options);
        typed.stop();

        setTimeout(function(){
            typed.start();
        }, 1500);
    } 

    // Events
    if($typed.length) {
        $typed.each(function(i) {
            init($(this), i);
        });
    }
}());


// Vimeo and YouTube video players

var Player = (function() {
    // Variables
    var $vimeo = $('.vimeo'),
        $youTube = $('.youtube');

    
    // Methods
    function playVimeo($this) {
        $('.vimeo').vimeo_player();
    }

    function playYouTube($this) {
        $('.youtube').YTPlayer();
    }
    

    // Events
    if($vimeo.length) {
        playVimeo();
    }

    if($youTube.length) {
        playYouTube();
    }
}());


// Animations

var Animation = (function() {
    // Variables
    var $aos = $('[data-aos]');
    
    
    // Methods
    function init() {
        if($('.preloader').length) {
            Preloader.then(function() {
                setTimeout(function(){
                    AOS.init({
                        offset: 150,
                        delay: 0,
                        once: true,
                    });
                }, 1000);
            }, function(error) {
    
            });
        } else {
            AOS.init({
                offset: 150,
                delay: 0,
                once: true,
            });
        }
    }
    

    // Events
    if($aos.length){
        init();
    }

}());